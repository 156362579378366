<template>
  <tr data-cy="leave-summary-report-record">
    <td>{{ leaveReport.first_name }}</td>
    <td>{{ leaveReport.last_name }}</td>
    <td>{{ departmentName }}</td>
    <td>{{ employeeCode }}</td>
    <td>{{ leaveReport.type }}</td>
    <td>{{ leaveReport.leave_count }}</td>
    <td>
      {{ leaveReport.amount }}
      {{ leaveReport.unit | pluralize(leaveReport.amount) }}
    </td>
  </tr>
</template>

<script>
export default {
  name: 'LeaveSummaryReportTableRow',

  props: {
    leaveReport: {
      type: Object,
      required: true,
    },
  },

  computed: {
    departmentName() {
      if (this.leaveReport.department_name) {
        return this.leaveReport.department_name
      }

      return '-'
    },

    employeeCode() {
      return this.leaveReport.employee_code
        ? this.leaveReport.employee_code
        : '-'
    },
  },
}
</script>

<template>
  <div>{{ holidayLocation }}</div>
</template>

<script>
export default {
  name: 'CompanyHolidayLocation',

  props: {
    location: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    holidayLocation() {
      if (this.location && this.location.state) {
        return `${this.location.country} (${this.location.state})`
      } else {
        return `${this.location.country}`
      }
    },

    locationName() {
      return this.location.name.toLowerCase()
    },
  },
}
</script>

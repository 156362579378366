<template>
  <div>
    <form class="tw-w-full">
      <div class="form-group">
        <div class="tw-w-full" data-cy="toil-instructions">
          <p class="tw-mb-6">
            If you offer time off in lieu to your team, then select which
            allowance you wish this to apply to:
          </p>
        </div>

        <div class="tw-w-full xl:tw-w-1/3">
          <div class="tw-relative">
            <VSelect
              id="report-type"
              :value="selectedAllowanceType"
              :options="selectableAllowanceTypes"
              :multiple="false"
              :searchable="false"
              :show-labels="false"
              :allow-empty="false"
              :max-height="160"
              track-by="id"
              label="name"
              data-cy="toil-allowance"
              placeholder=""
              @input="switchTimeOffInLieu"
            >
            </VSelect>
          </div>
        </div>
      </div>

      <template v-if="currentTimeOffInLieu">
        <div class="form-group">
          <div class="tw-w-full tw-flex tw-items-center">
            <ToggleButton
              :value="hasVisibilitySettings"
              :sync="true"
              :labels="true"
              color="#1da1f2"
              data-cy="btn-display-toil-on-wallchart"
              @change="toggleVisibility($event.value)"
            />

            <label class="tw-ml-2 tw-select-none tw-flex tw-items-center">
              <span class="tw-mr-1" data-cy="enable-toil-label"
                >Hide TOIL from the wall chart (except for approvers and admin
                users)</span
              >

              <button
                v-if="!!visibilitySettings"
                class="btn-link"
                type="button"
                data-cy="btn-congfigure-toil-on-wallchart"
                @click="$modal.show('toil-config-modal')"
              >
                Configure how this is shown
              </button>
            </label>
          </div>
        </div>

        <div class="form-group">
          <div class="tw-w-full tw-flex tw-items-center">
            <ToggleButton
              v-model="hasEnabledToRequestOvertime"
              :labels="true"
              color="#1da1f2"
              data-cy="btn-allow-emp-to-request-toil"
            />

            <label class="tw-ml-2 tw-select-none tw-flex tw-items-center">
              <span class="tw-mr-1">Allow employees to request TOIL.</span>
            </label>
          </div>
        </div>
      </template>
    </form>

    <Modal
      id="toil-config-modal"
      :classes="[
        'tw-shadow-md',
        'tw-bg-white',
        'tw-rounded-lg',
        'modal-overflow-visible',
      ]"
      :max-width="540"
      name="toil-config-modal"
      width="95%"
      height="auto"
      adaptive
      scrollable
    >
      <div class="modal-header">
        <div class="tw-flex tw-justify-between">
          <div>
            <p class="modal-title">
              Time Off In Lieu
            </p>
          </div>

          <div>
            <button
              class="modal-close"
              @click="$modal.hide('toil-config-modal')"
            >
              <SvgIcon name="close" class="tw-w-4 tw-h-4" />
            </button>
          </div>
        </div>
      </div>

      <div class="tw-mt-3 tw-p-3">
        <form
          v-if="!!visibilitySettings"
          class="tw-w-full"
          @submit.prevent="submit"
        >
          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label">
                Title
              </label>

              <input
                v-model="visibilitySettings.title"
                v-validate="'required'"
                v-focus
                name="title"
                class="form-control"
                type="text"
                autocomplete="off"
                data-cy="overtime-title-text"
              />

              <p
                v-show="errors.has('title')"
                class="tw-mt-1 tw-text-red-700 tw-text-sm"
              >
                {{ errors.first('title') }}
              </p>
            </div>
          </div>

          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label" for="colour">
                Display Colour <span class="required-field">&#42;</span>
              </label>

              <ColorPicker
                id="colour"
                :value="visibilitySettings.color"
                :palette="colorBucket"
                @input="item => (visibilitySettings.color = item.hex)"
              />
            </div>
          </div>

          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label">
                Options
              </label>

              <div class="tw-flex tw-items-center tw-leading-none">
                <ToggleButton
                  v-model="visibilityIsPublic"
                  :labels="true"
                  color="#1da1f2"
                />

                <label class="tw-ml-2 tw-select-none tw-flex tw-items-center">
                  <span>
                    The amount and the reason should be shown publicly on the
                    Wall Chart
                  </span>

                  <ExtraInfo icon="question" class="tw-inline-block tw--mt-1">
                    <div class="tw-p-4 tw-w-48">
                      If this setting is 'Off' then the Time off in Lieu amount
                      and reason will only be shown to Admins, Approvers and the
                      person who earned it. If it is 'On' then it will be shown
                      to everyone.
                    </div>
                  </ExtraInfo>
                </label>
              </div>
            </div>
          </div>

          <div class="tw-flex tw-flex-wrap tw-mb-3">
            <div class="tw-w-full tw-px-3">
              <div class="tw-flex tw-justify-end">
                <button
                  :disabled="!valid || loading"
                  class="btn btn-blue tw-rounded-lg"
                  type="submit"
                  data-cy="btn-save-overtime-display-settings"
                  @click="updateVisibilitySettings"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { Compact } from 'vue-color'
import moment from 'moment-timezone'
import VSelect from 'vue-multiselect'
import { sortBy, find } from 'lodash-es'
import { ToggleButton } from 'vue-js-toggle-button'
import ValidatesForm from '@/mixins/ValidatesForm'
import { Company } from '@/api'
const ExtraInfo = () => import('@/components/ExtraInfo')

export default {
  name: 'TimeOffInLieu',

  components: {
    VSelect,
    ExtraInfo,
    ToggleButton,
    ColorPicker: Compact,
  },

  mixins: [ValidatesForm],

  props: {
    allowanceTypes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      hasVisibilitySettings: false,
      calendars: [],
      visibilitySettings: null,
      currentTimeOffInLieu: null,
      colorBucket: [
        '#F05451',
        '#ff9f43',
        '#fcf600',
        '#1cd1a1',
        '#00a3a4',
        '#4ddbfb',
        '#35aaf0',
        '#1561bd',
        '#5f27cd',
        '#9f7de1',
        '#f183da',
        '#576574',
        '#f8baba',
        '#ffd9b4',
        '#fefb99',
        '#d3f5d6',
        '#99dadb',
        '#b8f1fd',
        '#aeddf9',
        '#a1c0e5',
        '#bfa9eb',
        '#d9cbf3',
        '#fba7e9',
        '#bcc1c7',
      ],
    }
  },

  computed: {
    defaultCalendar() {
      const currentYearCalendar = this.calendars.find(calendar => {
        return moment
          .utc(calendar.start_date)
          .isSame(moment().utc(true), 'year')
      })

      return currentYearCalendar || this.calendars[0]
    },

    selectedAllowanceType() {
      let allowanceTypeId = ''

      if (this.currentTimeOffInLieu) {
        allowanceTypeId = this.currentTimeOffInLieu.allowance_type_id
      }

      return find(this.selectableAllowanceTypes, { id: allowanceTypeId })
    },

    sortedAllowanceTypes() {
      return sortBy(this.allowanceTypes, 'name')
    },

    selectableAllowanceTypes() {
      return [
        {
          id: '',
          name: 'Not offered',
        },
        ...this.sortedAllowanceTypes,
      ]
    },

    visibilityIsPublic: {
      get() {
        return !!this.visibilitySettings && !this.visibilitySettings.is_private
      },
      set(isPublic) {
        if (!this.visibilitySettings) return

        this.visibilitySettings.is_private = !isPublic
      },
    },

    hasEnabledToRequestOvertime: {
      get() {
        return this.activeCompany.allow_employees_to_request_overtime
      },

      set(enabled) {
        this.toggleEnableOvertimeRequests(enabled)
      },
    },
  },

  watch: {
    '$route.query.company': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.fetchCalendars().then(() => this.fetchCurrentTimeOffInLieu())
      },
    },

    activeCompany: {
      deep: true,
      immediate: true,
      handler(company) {
        const visibilitySettings = company.time_off_in_lieu_visibility_settings

        this.visibilitySettings = visibilitySettings

        this.hasVisibilitySettings = !visibilitySettings
      },
    },
  },

  methods: {
    ...mapActions('auth', ['fetchUser']),

    async toggleVisibility(enable) {
      this.hasVisibilitySettings = enable

      enable
        ? await this.disableTimeOffInLieuVisibility()
        : await this.enableTimeOffInLieuVisibility()

      this.fetchUser()
    },

    async toggleEnableOvertimeRequests(enabled) {
      this.loading = true

      try {
        await Company.update(this.activeCompany, {
          allow_employees_to_request_overtime: enabled,
        })

        const status = enabled ? 'enabled' : 'disabled'

        this.success(`Time off in lieu requests ${status} successfully.`)
      } catch ({ response }) {
        this.validateFromResponse(response, true)
      }

      this.fetchUser()

      this.loading = false
    },

    async enableTimeOffInLieuVisibility() {
      this.loading = true

      try {
        await this.$http.post('time-off-in-lieu-visibility-settings', {
          company_id: this.activeCompany.id,
        })
      } catch ({ response }) {
        this.fetchUser()

        this.validateFromResponse(response, true)
      }

      this.loading = false
    },

    async disableTimeOffInLieuVisibility() {
      this.loading = true

      try {
        await this.$http.delete(
          `time-off-in-lieu-visibility-settings/${this.visibilitySettings.id}`,
          {
            params: {
              company_id: this.activeCompany.id,
            },
          }
        )
      } catch ({ response }) {
        this.fetchUser()

        this.validateFromResponse(response, true)
      }

      this.loading = false
    },

    async updateVisibilitySettings() {
      this.loading = true

      try {
        await this.$http.put(
          `time-off-in-lieu-visibility-settings/${this.visibilitySettings.id}`,
          {
            ...this.visibilitySettings,
          }
        )

        this.success('Time off in Lieu configuration updated.')

        const { data } = await this.fetchUser()

        this.loading = false

        return data
      } catch ({ response }) {
        this.validateFromResponse(response, false)

        this.loading = false
      }
    },

    async switchTimeOffInLieu(allowanceType) {
      this.loading = true

      if (this.currentTimeOffInLieu == null) {
        await this.storeTimeOffInLieu(allowanceType.id)
      } else if (allowanceType.id === '') {
        await this.deleteTimeOffInLieu()
      } else {
        await this.updateTimeOffInLieu(allowanceType.id)
      }

      await this.fetchCurrentTimeOffInLieu()

      this.fetchUser()

      this.loading = false
    },

    async storeTimeOffInLieu(allowanceTypeId) {
      await this.$http.post('time-off-in-lieu', {
        company_id: this.activeCompany.id,
        allowance_type_id: allowanceTypeId,
      })

      this.success('Time off in lieu activated successfully.')
    },

    async updateTimeOffInLieu(allowanceTypeId) {
      await this.$http.put('time-off-in-lieu/' + this.currentTimeOffInLieu.id, {
        company_id: this.activeCompany.id,
        allowance_type_id: allowanceTypeId,
      })

      this.success('Time off in lieu activated successfully.')
    },

    async deleteTimeOffInLieu() {
      const confirmed = await this.confirm(
        'Are you sure you want to turn this off? Any existing time off in lieu will be deleted.',
        'Turn off TOIL'
      )

      if (!confirmed) return

      await this.$http.delete(
        'time-off-in-lieu/' + this.currentTimeOffInLieu.id,
        {
          params: {
            company_id: this.activeCompany.id,
          },
        }
      )

      this.success('Time off in lieu disabled successfully.')
    },

    async fetchCurrentTimeOffInLieu() {
      this.loading = true

      try {
        const { data } = await this.$http.get('time-off-in-lieu', {
          params: {
            company_id: this.activeCompany.id,
            calendar: this.defaultCalendar.id,
          },
        })

        this.currentTimeOffInLieu = data[0]
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },

    async fetchCalendars() {
      this.loading = true

      try {
        const { data } = await this.$http.get('calendars', {
          params: {
            company_id: this.activeCompany.id,
          },
        })

        this.calendars = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },
  },
}
</script>

<template>
  <div
    :class="{ 'active-plan': value.id == plan.id }"
    class="tw-p-10 tw-rounded-lg tw-bg-gray-200 hover:tw-bg-blue-200 tw-cursor-pointer tw-text-center"
    data-cy="click-plan"
    @click="$emit('input', plan)"
  >
    <div
      class="tw-mb-2 tw-text-xl tw-font-semibold tw-text-gray-700"
      data-cy="plan-name"
    >
      {{ plan.name }}
      <span v-if="plan.saving" class="tw-text-sm"
        >save {{ plan.saving }}&#37;</span
      >
    </div>
    <div
      class="tw-mb-2 tw-flex-1 tw-text-4xl tw-font-semibold tw-text-gray-800"
      data-cy="billing-rate"
    >
      {{ displayPrice }}
      <span v-if="isDollar" class="tw-text-sm">({{ plan.currency }})</span>
    </div>
    <div>Per user per month</div>
  </div>
</template>

<script>
import FormatNumbers from './../mixins/FormatNumbers'

export default {
  name: 'Plan',

  mixins: [FormatNumbers],

  props: {
    value: {
      type: Object,
      required: true,
    },

    plan: {
      type: Object,
      required: true,
    },

    location: {
      type: Object,
      required: true,
    },
  },

  computed: {
    currencySymbol() {
      if (this.plan.currency === 'EUR') return '€'

      if (this.plan.currency === 'GBP') return '£'

      return '$'
    },

    isDollar() {
      return this.currencySymbol === '$'
    },

    displayPrice() {
      return this.currencySymbol + this.toFixed(this.plan.price, 2, true)
    },
  },
}
</script>

<style lang="scss" scoped>
.active-plan {
  @apply .bg-blue-500;
  @apply .shadow-inner;
}

.active-plan div {
  @apply .text-white;
}
</style>

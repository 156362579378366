<template>
  <component :is="getBillingComponent"></component>
</template>

<script>
import { mask } from 'vue-the-mask'
import FeatureFlags from '@/mixins/FeatureFlags'
import NewBilling from '@/pages/settings/NewBilling.vue'

const page = path => () =>
  import(/* webpackChunkName: '' */ `@/pages/${path}`).then(m => m.default || m)

export default {
  name: 'BillingContainer',

  directives: { mask },

  components: [NewBilling],

  mixins: [FeatureFlags],

  computed: {
    getBillingComponent() {
      if (this.isOn('dev-410-billing-portal')) {
        return NewBilling
      }
      return page('settings/Billing.vue')
    },
  },
}
</script>

<template>
  <tr data-cy="leave-report-record">
    <td>{{ leaveReport.first_name }}</td>
    <td>{{ leaveReport.last_name }}</td>
    <td>{{ departmentName }}</td>
    <td>{{ employeeCode }}</td>
    <td>
      <a
        class="tw-text-blue-500 tw-cursor-pointer hover:tw-underline"
        @click.prevent="showLeave"
      >
        {{ leaveReport.type }}
      </a>
    </td>
    <td>
      {{ leaveReport.amount }}
      {{ leaveReport.unit | pluralize(leaveReport.amount) }}
    </td>
    <td>{{ from }}</td>
    <td>{{ to }}</td>
    <td>{{ requestedAt }}</td>
    <td>{{ leaveReport.status }}</td>
    <td>{{ leaveStatusUpdatedAt }}</td>
    <td>{{ leaveReport.status_updated_by }}</td>
    <td>{{ leaveReport.private }}</td>
    <td class="ld-td_whitespace-normal">{{ reason }}</td>
  </tr>
</template>

<script>
import FormatDate from '@/mixins/FormatDate'

export default {
  name: 'LeaveDetailReportTableRow',

  mixins: [FormatDate],

  props: {
    leaveReport: {
      type: Object,
      required: true,
    },
  },

  computed: {
    departmentName() {
      if (this.leaveReport.department_name) {
        return this.leaveReport.department_name
      }

      return '-'
    },

    employeeCode() {
      return this.leaveReport.employee_code
        ? this.leaveReport.employee_code
        : '-'
    },

    reason() {
      return this.leaveReport.reason ? this.leaveReport.reason : '-'
    },

    from() {
      return this.formatShortDateFromIsoToHumanReadable(
        this.leaveReport.from_time,
        this.leaveReport.timezone
      )
    },

    to() {
      return this.formatShortDateFromIsoToHumanReadable(
        this.leaveReport.to_time,
        this.leaveReport.timezone
      )
    },

    requestedAt() {
      return this.formatShortDateFromIsoToHumanReadable(
        this.leaveReport.requested_at
      )
    },

    leaveStatusUpdatedAt() {
      return this.formatShortDateFromIsoToHumanReadable(
        this.leaveReport.status_updated_at
      )
    },
  },

  methods: {
    showLeave() {
      this.$router.push(
        {
          query: {
            ...this.$route.query,
            'leave-request': this.leaveReport.id,
          },
        },
        () => {}
      )
    },
  },
}
</script>

<template>
  <tr>
    <td>{{ invoice.number }}</td>
    <td>{{ invoiceDate }}</td>
    <td>{{ invoiceTotal }}</td>
    <td>
      <a
        v-if="isPaymentActionRequired"
        :href="invoice.redirect_url"
        class="btn btn-link tw-p-0"
      >
        Authenticate
      </a>
      <a v-else-if="hasPaymentFailed">
        Payment required
      </a>
      <span v-else>{{ status }}</span>
    </td>
    <td style="width: 160px">
      <div class="tw-flex tw-items-center tw-justify-end">
        <SpinnerButton
          :disabled="loading"
          :loading="loading"
          :spinner-only="true"
          theme="white"
          :spinner-classes="['tw-h-2 tw-w-2 tw-text-gray-700']"
          class="tw-px-3 tw-py-0 tw-h-8"
          type="button"
          @click="downloadInvoice"
        >
          Download
        </SpinnerButton>
      </div>
    </td>
  </tr>
</template>

<script>
import { startCase } from 'lodash-es'
import SpinnerButton from '@/components/SpinnerButton'
import FormatDate from '../../mixins/FormatDate'
import FileSaver from 'file-saver'
import numeral from 'numeral'
import ValidatesForm from '../../mixins/ValidatesForm'

export default {
  name: 'LocalInvoicesTableRow',

  components: { SpinnerButton },

  mixins: [FormatDate, numeral, ValidatesForm],

  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    invoiceDate() {
      return this.formatDateFromIsoToDayReadableDayNumberShortMonthYear(
        this.invoice.date
      )
    },

    invoiceTotal() {
      return (
        this.currencySymbol +
        numeral(this.invoice.total.toFixed(2)).format('0,0.00')
      )
    },

    status() {
      return startCase(this.invoice.status)
    },

    currencySymbol() {
      return this.invoice.currency.symbol
    },

    isPaymentActionRequired() {
      return this.invoice.status == 'open' && !!this.invoice.redirect_url
    },

    hasPaymentFailed() {
      return this.invoice.status == 'open' && !this.invoice.redirect_url
    },
  },

  methods: {
    async downloadInvoice() {
      this.loading = true

      try {
        const { data } = await this.$http.get(
          `invoices/${this.invoice.id}/pdf`,
          {
            params: this.$route.query,
            responseType: 'blob',
          }
        )

        FileSaver.saveAs(new Blob([data]), 'invoice.pdf')
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },

    focusPaymentInput() {
      document.querySelector('#credit_card_number').focus()
    },
  },
}
</script>

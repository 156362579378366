<template>
  <div ref="reference" class="tw-flex tw-items-center">
    <div>
      <div
        class="tw-font-semibold tw-text-base tw-select-none tw-text-gray-800"
        data-cy="currency-dropdown"
      >
        {{ value }}
      </div>
      <div v-show="false" ref="tooltip">
        <div class="tw-rounded">
          <a
            v-for="currency in currencies"
            :key="currency"
            class="tw-block tw-p-4 tw-cursor-pointer tw-no-underline hover:tw-bg-gray-300 tw-overflow-hidden"
            @click="selectCurrency(currency)"
          >
            <span class="tw-font-normal">{{ currency }}</span>
          </a>
        </div>
      </div>
    </div>
    <svg-icon
      name="cheveron-down"
      class="tw-w-6 tw-h-6 tw--mr-2 tw-text-gray-800"
    />
  </div>
</template>

<script>
import tippy from 'tippy.js'

export default {
  name: 'CurrencySwitcher',

  props: {
    currencies: {
      type: Array,
      required: true,
    },

    value: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.tippy = tippy.one(this.$refs.reference, {
      html: this.$refs.tooltip.firstChild,
      trigger: 'click',
      position: 'bottom',
      interactive: true,
      theme: 'light',
      animation: 'scale',
      inertia: true,
      arrow: true,
    })

    this.$once('hook:beforeDestroy', () => {
      this.tippy.destroy()
    })
  },

  methods: {
    selectCurrency(selectedCurrency) {
      this.tippy.hide()

      this.$emit('input', selectedCurrency)
    },
  },
}
</script>

<template>
  <div
    v-scroll-hint
    class="table-responsive settings-table"
    data-cy="invoices-table"
  >
    <table
      class="ld-table ld-table-striped ld-table-sm"
      data-cy="invoices-header"
    >
      <thead>
        <tr>
          <th>Invoice Number</th>
          <th>Invoice Date</th>
          <th>Total Amount</th>
          <th>Status</th>
          <th style="width: 160px"></th>
        </tr>
      </thead>
      <tbody data-cy="invoices-list">
        <template v-if="upcomingInvoices.length">
          <upcoming-invoice-table-row
            v-for="invoice in upcomingInvoices"
            :key="invoice.date"
            :invoice="invoice"
          >
          </upcoming-invoice-table-row>
        </template>
        <template v-if="localInvoices.length">
          <local-invoices-table-row
            v-for="invoice in localInvoices"
            :key="invoice.id"
            :invoice="invoice"
          >
          </local-invoices-table-row>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
const LocalInvoicesTableRow = () => import('./LocalInvoicesTableRow.vue')
const UpcomingInvoiceTableRow = () => import('./UpcomingInvoiceTableRow.vue')

export default {
  name: 'InvoicesTable',

  components: { LocalInvoicesTableRow, UpcomingInvoiceTableRow },

  props: {
    localInvoices: {
      type: Array,
      required: true,
    },
    upcomingInvoices: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<template>
  <tr data-cy="emp-holidays-report-table-row">
    <td>{{ holiday.first_name }}</td>
    <td>{{ holiday.last_name }}</td>
    <td>{{ departmentName }}</td>
    <td>{{ employeeCode }}</td>
    <td>{{ holidayName }}</td>
    <td>{{ Holiday }}</td>
  </tr>
</template>

<script>
export default {
  name: 'EmploymentHolidaysReportTableRow',

  props: {
    holiday: {
      type: Object,
      required: true,
    },
  },

  computed: {
    departmentName() {
      return this.holiday.department_name ? this.holiday.department_name : '-'
    },

    employeeCode() {
      return this.holiday.employee_code ? this.holiday.employee_code : '-'
    },

    holidayName() {
      return this.holiday.holiday_name ? this.holiday.holiday_name : '-'
    },

    Holiday() {
      return this.holiday.holiday_date ? this.holiday.holiday_date : '-'
    },
  },
}
</script>

<template>
  <tr>
    <td>{{ invoice.number }}</td>
    <td>{{ invoiceDate }}</td>
    <td>{{ invoice.total }}</td>
    <td>{{ invoice.status }}</td>
    <td style="width: 160px">
      <div class="tw-flex tw-items-center tw-justify-end">
        <SpinnerButton
          :disabled="loading"
          :loading="loading"
          :spinner-only="true"
          theme="white"
          :spinner-classes="['tw-h-2 tw-w-2 tw-text-gray-700']"
          class="tw-px-3 tw-py-0 tw-h-8"
          type="button"
          data-cy="btn-download-invoice"
          @click="downloadInvoice"
        >
          Download
        </SpinnerButton>
      </div>
    </td>
  </tr>
</template>

<script>
import SpinnerButton from '@/components/SpinnerButton'
import FormatDate from '../../mixins/FormatDate'
import FileSaver from 'file-saver'
import ValidatesForm from '../../mixins/ValidatesForm'

export default {
  name: 'UpcomingInvoiceTableRow',

  components: { SpinnerButton },

  mixins: [FormatDate, ValidatesForm],

  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    invoiceDate() {
      return this.formatDateFromIsoToDayReadableDayNumberShortMonthYear(
        this.invoice.date
      )
    },
  },

  methods: {
    async downloadInvoice() {
      this.loading = true

      try {
        const { data } = await this.$http.get(`upcoming-invoices/pdf`, {
          params: this.$route.query,
          responseType: 'blob',
        })
        FileSaver.saveAs(new Blob([data]), 'invoice.pdf')
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },
  },
}
</script>

<template>
  <div>
    <div class="md:tw-flex tw-mt-4 tw--mx-2">
      <div class="tw-w-full md:tw-w-2/3 tw-px-2">
        <div v-if="hasInvoices" class="card" data-cy="invoices">
          <div class="lg:tw-px-4">
            <h2 class="tw-mt-2 tw-mb-6 tw-text-gray-800">Invoices</h2>

            <InvoicesTable
              :local-invoices="localInvoices"
              :upcoming-invoices="upcomingInvoices"
            >
            </InvoicesTable>
          </div>
          <div
            v-if="localInvoicesNextPageUri"
            class="lg:tw-px-4 tw-text-center"
          >
            <div class="tw-mt-6 tw-text-gray-800">
              <a
                class="btn-link"
                :class="{ 'tw-pointer-events-none': loading }"
                href="#"
                @click.prevent="fetchInvoices(true)"
              >
                Load More
              </a>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="lg:tw-px-4">
            <h2 class="tw-mt-2 tw-mb-6 tw-text-gray-800">
              Billing Details
            </h2>

            <form method="POST">
              <div class="sm:tw-flex tw--mx-4">
                <div class="tw-flex-1 sm:tw-w-1/2 tw-px-4">
                  <div class="form-group">
                    <div class="tw-w-full">
                      <label class="form-label" for="company_name">
                        Company Name <span class="required-field">&#42;</span>
                      </label>

                      <input
                        id="company_name"
                        v-model="form.company_name"
                        v-validate="'required'"
                        v-focus
                        name="company_name"
                        data-vv-as="company name"
                        data-vv-validate-on="input"
                        data-cy="billing-company-name"
                        class="form-control"
                        type="text"
                        autocomplete="off"
                      />

                      <p
                        v-show="errors.has('company_name')"
                        class="tw-mt-1 tw-text-red-700 tw-text-sm"
                      >
                        {{ errors.first('company_name') }}
                      </p>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full">
                      <label class="form-label" for="contact_name">
                        Contact Name <span class="required-field">&#42;</span>
                      </label>

                      <input
                        id="contact_name"
                        v-model="form.contact_name"
                        v-validate="'required'"
                        name="contact_name"
                        data-vv-as="contact name"
                        data-cy="billing-contact-name"
                        class="form-control"
                        autocomplete="off"
                        type="text"
                      />

                      <p
                        v-show="errors.has('contact_name')"
                        class="tw-mt-1 tw-text-red-700 tw-text-sm"
                      >
                        {{ errors.first('contact_name') }}
                      </p>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full">
                      <label class="form-label" for="email">
                        Email <span class="required-field">&#42;</span>
                      </label>

                      <input
                        id="email"
                        v-model="form.email"
                        v-validate="'required'"
                        name="email"
                        data-vv-as="email"
                        data-cy="billing-email"
                        class="form-control"
                        type="email"
                        autocomplete="off"
                        inputmode="email"
                      />

                      <p
                        v-show="errors.has('email')"
                        class="tw-mt-1 tw-text-red-700 tw-text-sm"
                      >
                        {{ errors.first('email') }}
                      </p>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full">
                      <label class="form-label" for="contact_number">
                        Phone <span class="required-field">&#42;</span>
                      </label>

                      <input
                        id="contact_number"
                        v-model="form.contact_number"
                        v-validate="'required'"
                        name="contact_number"
                        data-vv-as="phone"
                        data-cy="billing-contact-number"
                        autocomplete="off"
                        class="form-control"
                        type="text"
                        inputmode="tel"
                      />

                      <p
                        v-show="errors.has('contact_number')"
                        class="tw-mt-1 tw-text-red-700 tw-text-sm"
                      >
                        {{ errors.first('contact_number') }}
                      </p>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full">
                      <label class="form-label" for="vat_type">
                        VAT Type
                      </label>

                      <VSelect
                        id="vat_type"
                        v-model="form.vat_type"
                        v-validate="{ required: isVatTypeRequired }"
                        :options="sortedVatTypes"
                        :custom-label="vatTypeLabel"
                        :show-labels="false"
                        :allow-empty="false"
                        :max-height="170"
                        :placeholder="vatTypePlaceholder"
                        name="vat_type"
                        data-vv-as="vat type"
                        data-vv-validate-on="input"
                        track-by="value"
                        data-cy="vat-type"
                        @open="isVATTypeFieldFocused = true"
                        @close="isVATTypeFieldFocused = false"
                      >
                        <template #singleLabel="props">
                          <div
                            class="tw-flex tw-justify-start tw-items-center tw-content-center"
                          >
                            <flag
                              :squared="false"
                              :iso="props.option.iso_code"
                              class="tw-mr-2"
                            />

                            <div
                              class="tw-text-base tw-font-normal"
                              data-cy="selected-vat-type"
                            >
                              {{ props.option.name }}
                            </div>
                          </div>
                        </template>

                        <template #option="props">
                          <div class="tw-grid tw-grid-cols-3">
                            <div
                              class="tw-flex tw-justify-start tw-items-center tw-content-center tw-leading-zero"
                            >
                              <flag
                                :squared="false"
                                :iso="props.option.iso_code"
                                class="tw-mr-2"
                              />

                              <div
                                class="tw-text-base tw-font-normal tw-leading-none"
                                data-cy="selected-vat-type"
                              >
                                {{ props.option.name }}
                              </div>
                            </div>

                            <div
                              class="country-name tw-text-base tw-font-normal tw-leading-none tw-text-gray-600 tw-col-span-2"
                            >
                              {{ props.option.country_name }}
                            </div>
                          </div>
                        </template>

                        <template #noResult>
                          <span>No items found.</span>
                        </template>
                      </VSelect>

                      <p
                        v-show="errors.has('vat_type')"
                        class="tw-mt-1 tw-text-red-700 tw-text-sm"
                      >
                        {{ errors.first('vat_type') }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="tw-flex-1 sm:tw-w-1/2 tw-px-4">
                  <div class="form-group">
                    <div class="tw-w-full">
                      <label class="form-label" for="address_1">
                        Address 1 <span class="required-field">&#42;</span>
                      </label>

                      <input
                        id="address_1"
                        v-model="form.address_1"
                        v-validate="'required'"
                        name="address_1"
                        data-vv-as="address 1"
                        data-cy="billing-address-1"
                        autocomplete="off"
                        class="form-control"
                        type="text"
                      />

                      <p
                        v-show="errors.has('address_1')"
                        class="tw-mt-1 tw-text-red-700 tw-text-sm"
                      >
                        {{ errors.first('address_1') }}
                      </p>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full">
                      <label class="form-label" for="address_line2">
                        Address 2
                      </label>

                      <input
                        id="address_line2"
                        v-model="form.address_2"
                        name="address_line2"
                        data-cy="billing-address-2"
                        class="form-control"
                        type="text"
                        autocomplete="off"
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full">
                      <label class="form-label" for="zip_code">
                        Zip / Postal Code
                        <span class="required-field">&#42;</span>
                      </label>

                      <input
                        id="zip_code"
                        v-model="form.zip_code"
                        v-validate="'required'"
                        name="zip_code"
                        data-vv-as="zip/postal code"
                        data-cy="billing-zip"
                        class="form-control"
                        type="text"
                        autocomplete="off"
                      />

                      <p
                        v-show="errors.has('zip_code')"
                        class="tw-mt-1 tw-text-red-700 tw-text-sm"
                      >
                        {{ errors.first('zip_code') }}
                      </p>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full">
                      <label class="form-label" for="country">
                        Country <span class="required-field">&#42;</span>
                      </label>

                      <VSelect
                        id="country"
                        v-model="form.country"
                        v-validate="'required'"
                        :options="sortedCountries"
                        :show-labels="false"
                        :allow-empty="false"
                        :max-height="170"
                        :placeholder="countryPlaceholder"
                        name="country"
                        data-vv-as="country"
                        data-cy="billing-country"
                        label="name"
                        track-by="name"
                        @open="isCountryFieldFocused = true"
                        @close="isCountryFieldFocused = false"
                      >
                        <template #singleLabel="props">
                          <div
                            class="tw-flex tw-justify-start tw-items-center tw-content-center"
                          >
                            <flag
                              :squared="false"
                              :iso="props.option.iso_code"
                              class="tw-mr-2"
                            />

                            <div
                              class="tw-text-base tw-font-normal"
                              data-cy="selected-billing-country"
                            >
                              {{ props.option.name }}
                            </div>
                          </div>
                        </template>
                        <template #option="props">
                          <div
                            class="tw-flex tw-justify-start tw-items-center tw-content-center tw-leading-zero"
                          >
                            <flag
                              :squared="false"
                              :iso="props.option.iso_code"
                              class="tw-mr-2"
                            />

                            <div
                              class="tw-text-base tw-font-normal tw-leading-none"
                              data-cy="selected-billing-country"
                            >
                              {{ props.option.name }}
                            </div>
                          </div>
                        </template>

                        <template #noResult>
                          <span>No items found.</span>
                        </template>
                      </VSelect>

                      <p
                        v-show="errors.has('country')"
                        class="tw-mt-1 tw-text-red-700 tw-text-sm"
                      >
                        {{ errors.first('country') }}
                      </p>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full">
                      <label class="form-label" for="vat_number">
                        VAT Number
                      </label>

                      <input
                        id="vat_number"
                        v-model="form.vat_number"
                        name="vat_number"
                        data-cy="billing-vat-number"
                        class="form-control"
                        type="text"
                        :placeholder="vatNumberPlaceholder"
                        autocomplete="off"
                      />

                      <p
                        v-show="errors.has('vat_number')"
                        class="tw-mt-1 tw-text-red-700 tw-text-sm"
                      >
                        {{ errors.first('vat_number') }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="isSubscribed" class="sm:tw-flex tw--mx-4">
                <div class="tw-w-full tw-px-4">
                  <SpinnerButton
                    :disabled="customerDetailsSaving"
                    :loading="customerDetailsSaving"
                    :spinner-only="true"
                    type="button"
                    @click="saveCustomerDetails"
                  >
                    Save
                  </SpinnerButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="tw-w-full md:tw-w-1/3 tw-px-2">
        <div class="card">
          <div class="lg:tw-px-4" data-cy="current-plan">
            <h2 class="tw-mt-2 tw-mb-6 tw-text-gray-800">Current Plan</h2>

            <div
              v-if="!!paymentActionRequiredInvoice || !!paymentFailedInvoice"
            >
              <p
                v-if="!!paymentFailedInvoice"
                class="tw-mb-6 tw-text-red-500 tw-font-semibold"
              >
                There has been an issue with the payment. Please enter your card
                details below and authenticate the payment.
              </p>
              <span v-if="!!paymentActionRequiredInvoice">
                <p class="tw-mb-4 tw-text-red-500 tw-font-semibold">
                  Please&nbsp;<a
                    :href="paymentActionRequiredInvoice.redirect_url"
                    class="tw-underline hover:tw-text-red-900"
                    >authenticate</a
                  >&nbsp;your payment.
                </p>
              </span>
            </div>
            <div v-else-if="!hasProperSubscription">
              <p class="tw-mb-6 tw-text-red-500 tw-font-semibold">
                You have more users than allowed on the current plan ({{
                  numberOfSeats
                }}).
              </p>
              <p class="tw-mb-6 tw-text-red-500 tw-font-semibold">
                Your team will not be able to request leave until you subscribe
                to a new plan or reduce your number of users below the current
                plan limit ({{ numberOfSeats }}).
              </p>
            </div>
            <div v-else>
              <p class="tw-mb-6">
                You have an active subscription for
                <strong>{{ numberOfSeats }} </strong> users on
                <strong id="current-plan">{{ subscribedPlan }} Plan</strong>.
              </p>
              <p v-if="isCanceled" class="tw-mb-6 tw-text-red-500">
                Your current plan is scheduled to be cancelled on
                {{ currentPeriodEndsAt }}. To resume this subscription please
                press the 'Resume' button below.
              </p>
              <p v-else class="tw-mb-6">
                To change your plan or update the number of users, please fill
                in the section below. Once you do this your current plan will be
                updated.
              </p>
            </div>

            <SpinnerButton
              v-if="isCanceled"
              :disabled="subscriptionResuming"
              :loading="subscriptionResuming"
              :spinner-only="true"
              type="button"
              @click="resumeSubscription"
            >
              Resume
            </SpinnerButton>
          </div>
        </div>

        <div class="card">
          <div class="lg:tw-px-4">
            <form method="POST">
              <div
                v-if="plans.length && (!isSubscribed || isSubscriptionActive)"
              >
                <div class="tw-flex tw-justify-between tw-items-center">
                  <h2
                    class="tw-mt-2 tw-mb-6 tw-text-gray-800 tw-flex tw-items-center"
                  >
                    <span>Update Plan</span>
                    <ExtraInfo
                      v-if="isSubscribed"
                      icon="question"
                      class="tw--mt-2"
                    >
                      <div class="tw-p-4 tw-w-48">
                        Update your plan to increase or decrease the number of
                        users, or change from monthly to yearly.
                        <div>
                          <a
                            class="btn-link tw-font-semibold"
                            target="_blank"
                            href="https://help.leavedates.com/hc/en-us/articles/360006206680-Amend-the-subscribed-number-of-users"
                            >More info</a
                          >
                        </div>
                      </div>
                    </ExtraInfo>
                  </h2>
                  <div
                    class="tw-px-1 tw-pb-3 tw-cursor-pointer tw-inline-flex tw-items-center tw-no-underline"
                  >
                    <CurrencySwitcher
                      v-if="!hasLastSubscription && supportedCurrencies.length"
                      v-model="selectedCurrency"
                      :currencies="supportedCurrencies"
                    />
                  </div>
                </div>
                <div class="xl:tw-flex tw--mx-4">
                  <div
                    v-for="plan in plansForLocation"
                    :key="plan.id"
                    class="tw-flex-1 xl:tw-w-1/2 tw-px-4 tw-mb-10"
                  >
                    <Plan
                      v-model="selectedPlan"
                      :plan="plan"
                      :location="location"
                    ></Plan>
                  </div>
                </div>

                <div class="tw-mb-2 tw-flex tw-items-center tw-justify-between">
                  <div>
                    <div
                      class="tw-flex tw-items-center tw-justify-start tw-flex-wrap"
                      data-cy="new-plan"
                    >
                      <input
                        id="users"
                        v-model="quantity"
                        v-validate="'required'"
                        name="users"
                        data-vv-as="users"
                        data-cy="plan-users"
                        class="form-control tw-mr-4 tw-w-32"
                        type="number"
                        min="1"
                        inputmode="decimal"
                      />
                      <div>
                        users on
                        <span id="new-plan" class="tw-font-semibold"
                          >{{ selectedPlan.name }} Plan</span
                        >
                      </div>
                      <div>
                        <ExtraInfo
                          v-if="inValidQuantity"
                          class="extra-info__mini tw--mx-1"
                        >
                          <div class="tw-p-4 tw-w-48">
                            <span v-show="!isSubscribed">
                              You only need to upgrade for more than 5 users.
                            </span>
                            <span v-show="isSubscribed">
                              If you want to downgrade under 6 users, You must
                              cancel this plan and go to the free plan.
                            </span>
                          </div>
                        </ExtraInfo>
                      </div>
                    </div>
                  </div>
                  <div class="tw-font-semibold" data-cy="total-amount">
                    {{ currencySign }}{{ total }}
                  </div>
                </div>

                <div class="tw-mb-6 tw-flex tw-items-center tw-justify-start">
                  <div v-if="selectedPlan.saving" class="tw-text-sm">
                    You are saving
                    <span class="tw-font-semibold"
                      >{{ selectedPlan.saving }}&#37;</span
                    >
                    with this plan
                  </div>
                </div>

                <div class="w-full">
                  <span
                    data-cy="have-coupon-code"
                    class="tw-mr-4 hover:tw-underline tw-cursor-pointer"
                    @click="toggleCouponField"
                    >Have a coupon code?</span
                  >
                </div>

                <div
                  class="tw-mt-2 tw-pb-3 tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-justify-top"
                >
                  <input
                    v-if="couponFieldIsVisible"
                    id="couponCode"
                    v-model="couponCode"
                    name="coupon"
                    data-vv-as="coupon"
                    data-cy="coupon-code"
                    class="form-control tw-mr-4 tw-w-48"
                    type="text"
                    autocomplete="off"
                  />
                  <button
                    v-if="couponFieldIsVisible"
                    :disabled="!couponCode"
                    class="btn btn-blue p-0"
                    type="button"
                    data-cy="plan-apply-btn"
                    @click="validateCouponCode"
                  >
                    Apply
                  </button>
                </div>
                <div class="tw-mb-4">
                  <p
                    v-show="errors.has('coupon')"
                    class="tw-mb-6 tw-text-red-700 tw-text-sm"
                  >
                    {{ errors.first('coupon') }}
                  </p>
                </div>

                <div
                  class="tw-mb-1 tw-pb-3 tw-flex tw-items-center tw-justify-between"
                >
                  <div>Sub Total</div>
                  <div class="tw-font-semibold" data-cy="sub-total-amount">
                    {{ currencySign }}{{ total }}
                  </div>
                </div>

                <div
                  v-if="coupon.amount_off || coupon.percent_off"
                  class="tw-mb-1 tw-pb-3 tw-flex tw-items-center tw-justify-between"
                >
                  <div>
                    Coupon Code Discount(<span>{{ couponDetail }}</span
                    >)
                  </div>
                  <div
                    class="tw-font-semibold"
                    data-cy="coupon-discount-amount"
                  >
                    - {{ currencySign }}{{ couponDiscount }}
                  </div>
                </div>

                <div
                  class="tw-mb-1 tw-pb-3 tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-300"
                >
                  <div>VAT / Tax</div>
                  <div class="tw-font-semibold" data-cy="sales-tax">
                    {{ currencySign }}{{ tax }}
                  </div>
                </div>

                <div
                  class="tw-mb-12 tw-flex tw-items-center tw-justify-between tw-text-xl"
                >
                  <div><span class="tw-font-semibold">Total</span></div>
                  <div class="tw-font-semibold" data-cy="grand-total-amount">
                    {{ currencySign }}{{ grandTotal }}
                  </div>
                </div>
              </div>

              <h2 class="tw-mt-2 tw-mb-6 tw-text-gray-800">
                Pay with Credit Card
              </h2>

              <div class="tw-w-full">
                <div class="form-group" data-cy="credit-card-form">
                  <label class="form-label" for="credit_card_number">
                    Credit Card Number <span class="required-field">&#42;</span>
                  </label>
                  <input
                    id="credit_card_number"
                    v-model="form.credit_card_number"
                    v-validate="'required'"
                    v-mask="'#### #### #### ####'"
                    name="credit_card_number"
                    data-vv-as="credit card number"
                    data-cy="credit-card-number"
                    class="form-control"
                    type="text"
                    autocomplete="off"
                    placeholder="1234 5678 8765 4321"
                    inputmode="decimal"
                  />
                  <p
                    v-show="errors.has('credit_card_number')"
                    class="tw-mt-1 tw-text-red-700 tw-text-sm"
                  >
                    {{ errors.first('credit_card_number') }}
                  </p>
                </div>
              </div>

              <div class="xl:tw-flex tw--mx-4" data-cy="credit-card-form-2">
                <div class="xl:tw-w-1/3 tw-px-4">
                  <div class="form-group">
                    <label
                      class="form-label"
                      for="credit_card_expiration_month"
                    >
                      Expiry Month <span class="required-field">&#42;</span>
                    </label>
                    <input
                      id="credit_card_expiration_month"
                      v-model="form.credit_card_expiration_month"
                      v-validate="'required'"
                      name="credit_card_expiration_month"
                      data-vv-as="credit card expiration month"
                      data-cy="credit-card-expiry-month"
                      class="form-control"
                      type="number"
                      min="1"
                      max="12"
                      placeholder="12"
                      inputmode="decimal"
                    />
                    <p
                      v-show="errors.has('credit_card_expiration_month')"
                      class="tw-mt-1 tw-text-red-700 tw-text-sm"
                    >
                      {{ errors.first('credit_card_expiration_month') }}
                    </p>
                  </div>
                </div>

                <div class="xl:tw-w-1/3 tw-px-4">
                  <div class="form-group">
                    <label class="form-label" for="credit_card_expiration_year">
                      Expiry Year <span class="required-field">&#42;</span>
                    </label>
                    <input
                      id="credit_card_expiration_year"
                      v-model="form.credit_card_expiration_year"
                      v-validate="'required'"
                      name="credit_card_expiration_year"
                      data-vv-as="credit card expiration year"
                      data-cy="credit-card-expiry-year"
                      class="form-control"
                      type="number"
                      placeholder="2025"
                      inputmode="decimal"
                    />
                    <p
                      v-show="errors.has('credit_card_expiration_year')"
                      class="tw-mt-1 tw-text-red-700 tw-text-sm"
                    >
                      {{ errors.first('credit_card_expiration_year') }}
                    </p>
                  </div>
                </div>

                <div class="xl:tw-w-1/3 tw-px-4">
                  <div class="form-group">
                    <label class="form-label" for="cvv">
                      CVV <span class="required-field">&#42;</span>
                    </label>
                    <input
                      id="cvv"
                      v-model="form.credit_card_cvv"
                      v-validate="'required'"
                      name="credit_card_cvv"
                      data-vv-as="cvv"
                      data-cy="credit-card-cvv"
                      class="form-control"
                      type="number"
                      min="0"
                      max="9999"
                      inputmode="decimal"
                    />
                    <p
                      v-show="errors.has('credit_card_cvv')"
                      class="tw-mt-1 tw-text-red-700 tw-text-sm"
                    >
                      {{ errors.first('credit_card_cvv') }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="tw-w-full">
                  <input
                    id="terms_conditions"
                    v-model="terms.agree"
                    v-validate="'required'"
                    class="magic-checkbox"
                    data-vv-as="terms and conditions"
                    data-cy="terms-conditions"
                    name="terms_conditions"
                    type="checkbox"
                  />
                  <label
                    for="terms_conditions"
                    class="tw-select-none magic-checkbox-label"
                  >
                    I accept the
                    <a
                      :href="termsUrl"
                      class="tw-text-blue-500 hover:tw-underline"
                      target="_blank"
                      >Terms of Use</a
                    >
                    and the
                    <a
                      :href="privacyUrl"
                      class="tw-text-blue-500 hover:tw-underline"
                      target="_blank"
                      >Privacy Policy</a
                    >.
                  </label>
                  <p
                    v-show="errors.has('terms_conditions')"
                    class="tw-mt-1 tw-text-red-700 tw-text-sm"
                  >
                    {{ errors.first('terms_conditions') }}
                  </p>
                </div>
              </div>

              <div class="tw-flex tw-items-center tw-space-x-2">
                <SpinnerButton
                  v-if="!isSubscribed || isSubscriptionActive"
                  :disabled="subscribing || inValidQuantity"
                  :loading="subscribing"
                  :spinner-only="true"
                  type="button"
                  data-cy="upgrade-me"
                  @click="subscribe"
                >
                  Upgrade
                </SpinnerButton>

                <SpinnerButton
                  v-if="isSubscriptionActive"
                  :disabled="loading"
                  :class="isCanceled ? 'tw-pointer-events-none' : ''"
                  class="btn btn-red tw-border-red-500 hover:tw-border-red-700"
                  type="button"
                  @click="showCancelSubscription"
                >
                  Cancel
                </SpinnerButton>

                <SpinnerButton
                  v-if="isSubscribed && !isSubscriptionActive"
                  :disabled="paymentMethodUpdating"
                  :loading="paymentMethodUpdating"
                  :spinner-only="true"
                  type="button"
                  data-cy="upgrade-me"
                  @click="updatePaymentMethod"
                >
                  Complete Payment
                </SpinnerButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <Modal
      id="modal-cancel-subscription"
      :classes="[
        'tw-shadow-md',
        'tw-bg-white',
        'tw-rounded-lg',
        'modal-overflow-visible',
      ]"
      :max-width="480"
      name="modal-cancel-subscription"
      width="95%"
      height="auto"
      adaptive
      scrollable
      @before-open="resetCancelSubscriptionModelData"
    >
      <div class="modal-header">
        <div class="tw-flex tw-justify-between">
          <div>
            <p class="modal-title">
              Cancel Subscription?
            </p>
          </div>
          <div data-cy="edit-leave-close">
            <button
              class="modal-close"
              @click="$modal.hide('modal-cancel-subscription')"
            >
              <SvgIcon name="close" class="tw-w-4 tw-h-4" />
            </button>
          </div>
        </div>
      </div>

      <div class="tw-p-6">
        <p>{{ cancellationMessage }}</p>

        <form class="tw-w-full tw-max-w-md" data-vv-scope="cancel-subscription">
          <div class="form-group tw-mt-5">
            <div class="tw-w-full">
              <label class="form-label" for="reasonForCanceling">
                Reason for cancelling:
                <span class="required-field">&#42;</span>
              </label>
              <textarea
                id="reasonForCanceling"
                v-model="reasonForCanceling"
                v-validate.disable="'required|min:10'"
                v-focus
                class="form-control"
                data-vv-name="reason-for-canceling"
                data-vv-as="reason for cancelling"
                type="text"
                autocomplete="off"
                rows="5"
                tabindex="1"
              />
              <p
                v-show="errors.has('cancel-subscription.reason-for-canceling')"
                class="tw-mt-1 tw-text-red tw-text-sm"
              >
                Please provide a little more information.
              </p>
            </div>
          </div>
        </form>

        <div class="tw-mt-4 tw-flex tw-items-center tw-justify-end">
          <SpinnerButton
            :disabled="loading || subscriptionCanceling"
            :loading="subscriptionCanceling"
            :spinner-only="true"
            :spinner-classes="['tw-h-2 tw-w-2 tw-text-red-500']"
            as="link"
            theme="red"
            type="button"
            title="Cancel the Subscription"
            @click="cancelSubscription"
          >
            Cancel Subscription
          </SpinnerButton>

          <SpinnerButton
            data-cy="Close"
            type="button"
            @click="$modal.hide('modal-cancel-subscription')"
          >
            Close
          </SpinnerButton>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mask } from 'vue-the-mask'
import moment from 'moment-timezone'
import Plan from '@/components/Plan'
import { concat, sortBy, uniq } from 'lodash-es'
import FormatDate from '@/mixins/FormatDate'
import Subscription from '@/mixins/Subscription'
import ValidatesForm from '@/mixins/ValidatesForm'
import FormatNumbers from '@/mixins/FormatNumbers'
import InvoicesTable from '@/components/invoices/InvoicesTable'
import CurrencySwitcher from '@/components/CurrencySwitcher'
import SpinnerButton from '@/components/SpinnerButton'
import documentationUrls from '@/documentations/documentation-urls'

const VSelect = () => import('vue-multiselect')
const ExtraInfo = () => import('@/components/ExtraInfo')

export default {
  name: 'Billing',

  components: {
    CurrencySwitcher,
    InvoicesTable,
    Plan,
    ExtraInfo,
    VSelect,
    SpinnerButton,
  },

  directives: { mask },

  mixins: [ValidatesForm, FormatDate, Subscription, FormatNumbers],

  data: () => ({
    terms: {
      agree: false,
    },
    plans: [],
    selectedPlan: {},
    selectedCurrency: '',
    form: {
      credit_card_number: '',
      credit_card_expiration_year: '',
      credit_card_expiration_month: '',
      credit_card_cvv: '',
      company_name: '',
      vat_type: '',
      vat_number: '',
      contact_name: '',
      email: '',
      contact_number: '',
      address_1: '',
      address_2: '',
      zip_code: '',
      country: '',
    },
    couponCode: '',
    coupon: {
      percent_off: 0,
      amount_off: 0,
      code: '',
    },
    couponFieldIsVisible: false,
    quantity: 10,
    loading: false,
    subscribing: false,
    paymentMethodUpdating: false,
    subscriptionCanceling: false,
    subscriptionResuming: false,
    customerDetailsSaving: false,
    localInvoices: [],
    localInvoicesNextPageUri: null,
    upcomingInvoices: [],
    countries: [],
    vatTypes: [],
    location: {},
    reasonForCanceling: '',
    isCountryFieldFocused: false,
    isVATTypeFieldFocused: false,
  }),

  computed: {
    countryPlaceholder() {
      return this.isCountryFieldFocused ? 'Search' : ''
    },

    vatTypePlaceholder() {
      return this.isVATTypeFieldFocused ? 'Search' : ''
    },

    vatNumberPlaceholder() {
      return this.form.vat_type?.example
    },

    isVatTypeRequired() {
      return !!this.form.vat_number
    },

    tax() {
      return ((this.total * this.taxRate) / 100).toFixed(2)
    },

    taxRate() {
      if (!!this.form.country && this.form.country.iso_code === 'GB') {
        return 20
      }

      return 0
    },

    total() {
      return (
        this.quantity *
        this.selectedPlan.price *
        this.selectedPlan.interval
      ).toFixed(2)
    },

    grandTotal() {
      return Math.max(
        0,
        parseFloat(this.total) +
          parseFloat(this.tax) -
          parseFloat(this.couponDiscount)
      ).toFixed(2)
    },

    hasInvoices() {
      return this.allInvoices.length
    },

    couponDiscount() {
      if (this.coupon.percent_off) {
        return ((this.total * this.coupon.percent_off) / 100).toFixed(2)
      }

      return parseFloat(this.coupon.amount_off / 100).toFixed(2)
    },

    couponDetail() {
      if (this.coupon.percent_off) {
        return this.coupon.percent_off + '% Off'
      }
      return this.currencySign + this.coupon.amount_off / 100 + ' Off'
    },

    cancellationMessage() {
      return `We will process your downgrade after your current billing cycle ends on ${this.currentPeriodEndsAt}. After this time you will not be able to request leave until you have reduced your number of employees to below the free tier limit. Would you like to proceed with this cancellation?`
    },

    currentPeriodEndsAt() {
      if (!this.isSubscribed || (this.selectedPlan && !this.selectedPlan.name))
        return null

      const unit =
        this.selectedPlan.name.toLowerCase() === 'monthly' ? 'months' : 'years'

      return this.formatDateFromIsoToDayReadableDayNumberShortMonthYear(
        moment.utc(this.createdAt).add(1, unit),
        'utc'
      )
    },

    sortedCountries() {
      return sortBy(this.countries, 'name')
    },

    sortedVatTypes() {
      return sortBy(this.vatTypes, ['country_name', 'name'])
    },

    allInvoices() {
      return concat(this.localInvoices, this.upcomingInvoices)
    },

    currencySign() {
      if (this.selectedPlan.currency === 'EUR') return '€'

      if (this.selectedPlan.currency === 'GBP') return '£'

      return '$'
    },

    privacyUrl() {
      return documentationUrls.privacyUrl
    },

    termsUrl() {
      return documentationUrls.termsUrl
    },

    inValidQuantity() {
      return this.quantity <= 5
    },

    paymentFailedInvoice() {
      return this.localInvoices.find(invoice => {
        return invoice.status == 'open' && !invoice.redirect_url
      })
    },

    paymentActionRequiredInvoice() {
      return this.localInvoices.find(invoice => {
        return invoice.status == 'open' && !!invoice.redirect_url
      })
    },

    supportedCurrencies() {
      return uniq(
        this.plans.map(plan => {
          return plan.currency
        })
      )
    },

    plansForLocation() {
      return this.plans.filter(plan => {
        return plan.currency === this.selectedCurrency
      })
    },
  },

  watch: {
    '$route.query.company': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.fetch()
      },
    },

    selectedPlan(newPlan) {
      const isAnnualPlan = newPlan.id.toLowerCase().includes('annually')
      const isMonthlyCoupon =
        this.coupon.code.toLowerCase().includes('month') ||
        this.coupon.code.toLowerCase().includes('monthly')

      if (isAnnualPlan && isMonthlyCoupon) {
        this.couponCode = ''
        this.coupon = {
          percent_off: 0,
          amount_off: 0,
          code: '',
        }
      }
    },

    location(value) {
      this.selectedCurrency = value.currency
    },

    plansForLocation(plans) {
      if (plans.length && !this.hasLastSubscription) {
        this.selectedPlan = plans[0]
      }
    },
  },

  methods: {
    ...mapActions('auth', ['fetchUser']),

    async fetch() {
      this.fetchInvoices()
      this.fetchUpcomingInvoices()

      await Promise.all([
        this.fetchPlans(),
        this.fetchUser(),
        this.fetchCountries(),
        this.fetchVatTypes(),
      ])

      this.fillForm()
    },

    vatTypeLabel({ name, country_name }) {
      return `${name} ${country_name}`
    },

    async fetchCountries() {
      this.loading = true

      try {
        const { data } = await this.$http.get('countries')

        this.countries = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },

    async fetchVatTypes() {
      this.loading = true

      try {
        const { data } = await this.$http.get('vat-types')

        this.vatTypes = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },

    async subscribe() {
      this.errors.clear()

      if (!this.terms.agree) {
        this.errors.add({
          field: 'terms_conditions',
          msg: 'You must accept the Terms of Use and Privacy Policy.',
        })
        return
      }

      await this.validate()

      if (!this.valid) return

      const method = this.isSubscribed ? 'put' : 'post'

      this.subscribing = true

      try {
        await this.$http[method]('subscriptions', {
          company_id: this.activeCompany.id,
          plan_id: this.selectedPlan.id,
          quantity: this.quantity,
          ...this.form,
          country: this.form.country.iso_code,
          vat_type: this.form.vat_type?.value,
          vat_country: this.form.vat_type?.iso_code,
          coupon: this.coupon ? this.coupon.code : null,
        })

        await this.fetch()

        this.checkForNewInvoices()

        this.success('You have successfully subscribed.')

        this.errors.clear()
      } catch ({ response }) {
        if (response.data.redirect_url) {
          window.location = response.data.redirect_url
        } else {
          this.validateFromResponse(response)
        }
      }

      this.subscribing = false
    },

    async fetchPlans() {
      this.loading = true

      try {
        const { data } = await this.$http.get('billing/plans', {
          params: {
            company: this.activeCompany.id,
          },
        })

        this.plans = data.plans

        this.location = data.location
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },

    async validateCouponCode() {
      this.resetCoupon()

      if (!this.couponCode) return

      try {
        const { data } = await this.$http.post('coupons', {
          code: this.couponCode,
          plan: this.selectedPlan,
        })
        this.coupon = { ...data }
      } catch ({ response }) {
        this.errors.add({
          field: 'coupon',
          msg: response.data.message,
        })
      }

      this.couponCode = ''
    },

    fillForm() {
      this.reset()

      if (this.isSubscribed) {
        const subscription = this.activeCompany.current_subscription

        this.form = {
          credit_card_number: '',
          credit_card_expiration_year: '',
          credit_card_expiration_month: '',
          credit_card_cvv: '',
          company_name: subscription.company_name,
          vat_type: this.vatTypes.find(
            vatType =>
              vatType.value === subscription.vat_type &&
              vatType.iso_code === subscription.vat_country
          ),
          vat_number: subscription.vat_number,
          contact_name: subscription.contact_name,
          email: subscription.email,
          contact_number: subscription.contact_number,
          address_1: subscription.address_1,
          address_2: subscription.address_2,
          zip_code: subscription.zip_code,
          country: this.countries.find(country => {
            return (
              country.iso_code === subscription.country ||
              country.name === subscription.country
            )
          }),
        }

        this.quantity = this.toNearestTenth(subscription.quantity)
      }

      if (this.hasLastSubscription) {
        this.selectedCurrency = this.activeCompany.last_subscription.plan.currency
        this.selectedPlan = this.activeCompany.last_subscription.plan
      }
    },

    checkForNewInvoices() {
      const newInvoicesTimeout = setTimeout(() => {
        this.fetchInvoices()
      }, 3000)

      this.$once('hook:beforeDestroy', () => {
        clearTimeout(newInvoicesTimeout)
      })
    },

    async fetchInvoices(isPaging = false) {
      this.loading = true

      let url = isPaging
        ? `invoices${this.localInvoicesNextPageUri}`
        : 'invoices'

      try {
        const { data } = await this.$http.get(url, {
          params: this.$route.query,
        })

        this.localInvoices = isPaging
          ? [...this.localInvoices, ...data.data]
          : data.data

        this.localInvoicesNextPageUri = data.next_page_url
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },

    async fetchUpcomingInvoices() {
      this.loading = true

      try {
        const { data } = await this.$http.get('upcoming-invoices', {
          params: this.$route.query,
        })

        this.upcomingInvoices = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },

    showCancelSubscription() {
      this.$modal.show('modal-cancel-subscription')
    },

    async cancelSubscription() {
      const valid = await this.validate('cancel-subscription')

      if (!valid) return

      this.subscriptionCanceling = true

      await this.$http.delete('current-subscription', {
        params: {
          ...this.$route.query,
          reason_for_canceling: this.reasonForCanceling,
        },
      })

      this.fetch()

      this.$modal.hide('modal-cancel-subscription')

      this.$nextTick(() => {
        this.reasonForCanceling = ''

        this.$validator.reset()
      })

      this.success('Subscription cancelled.')

      this.subscriptionCanceling = false
    },

    reset() {
      this.form = {
        credit_card_number: '',
        credit_card_expiration_year: '',
        credit_card_expiration_month: '',
        credit_card_cvv: '',
        company_name: '',
        vat_type: '',
        vat_number: '',
        contact_name: '',
        email: '',
        contact_number: '',
        address_1: '',
        address_2: '',
        zip_code: '',
        country: '',
      }

      this.couponCode = ''

      this.coupon = {
        percent_off: 0,
        amount_off: 0,
        code: '',
      }

      this.couponFieldIsVisible = false

      this.reasonForCanceling = ''

      this.quantity = this.toNearestTenth(6)

      this.$validator.reset()
    },

    resetCoupon() {
      this.coupon.percent_off = 0
      this.coupon.amount_off = 0
      this.errors.clear()
    },

    resetCancelSubscriptionModelData() {
      this.reasonForCanceling = ''
    },

    async saveCustomerDetails() {
      this.errors.clear()

      this.customerDetailsSaving = true

      try {
        await this.$http.put('subscribers', {
          company_id: this.activeCompany.id,
          ...this.form,
          vat_type: this.form.vat_type?.value,
          vat_country: this.form.vat_type?.iso_code,
          country: this.form.country.iso_code,
        })

        this.fetch()

        this.success('Customer updated.')

        this.errors.clear()
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.customerDetailsSaving = false
    },

    async resumeSubscription() {
      const confirmed = await this.confirm(
        'Are you sure you wish to resume the subscription?',
        'Resume subscription'
      )

      if (!confirmed) return

      this.subscriptionResuming = true

      try {
        await this.$http.put('current-subscription', {
          company_id: this.activeCompany.id,
        })

        await this.fetch()

        this.success('Subscription resumed.')
      } catch ({ response }) {
        this.validateFromResponse(response, true)
      }

      this.subscriptionResuming = false
    },

    toggleCouponField() {
      this.couponFieldIsVisible = !this.couponFieldIsVisible
      this.errors.remove('coupon')
    },

    async updatePaymentMethod() {
      if (!this.terms.agree) {
        this.errors.add({
          field: 'terms_conditions',
          msg: 'You must accept the Terms of Use and Privacy Policy.',
        })
        return
      }

      await this.validate()

      if (!this.valid) return

      this.paymentMethodUpdating = true

      try {
        await this.$http.put('payment-methods', {
          company_id: this.activeCompany.id,
          ...this.form,
          vat_type: this.form.vat_type?.value,
          vat_country: this.form.vat_type?.iso_code,
          country: this.form.country.iso_code,
        })

        this.fetch()
        this.checkForNewInvoices()

        this.success('Payment completed successfully.')
      } catch ({ response }) {
        if (response.data.redirect_url) {
          window.location = response.data.redirect_url
        } else {
          this.validateFromResponse(response)
        }
      }

      this.paymentMethodUpdating = false
    },
  },
}
</script>

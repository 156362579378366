<template>
  <tr>
    <td>{{ summarisedOvertime.first_name }}</td>
    <td>{{ summarisedOvertime.last_name }}</td>
    <td>{{ departmentName }}</td>
    <td>{{ employeeCode }}</td>
    <td>{{ summarisedOvertime.total_count }}</td>
    <td>
      {{ summarisedOvertime.total_amount }}
      {{ summarisedOvertime.unit | pluralize(summarisedOvertime.total_amount) }}
    </td>
  </tr>
</template>

<script>
export default {
  name: 'OvertimeSummaryReportTableRow',

  props: {
    summarisedOvertime: {
      type: Object,
      required: true,
    },
  },

  computed: {
    departmentName() {
      if (this.summarisedOvertime.department) {
        return this.summarisedOvertime.department
      }

      return '-'
    },

    employeeCode() {
      return this.summarisedOvertime.employee_code
        ? this.summarisedOvertime.employee_code
        : '-'
    },
  },
}
</script>
